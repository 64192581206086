export const menuMix = {
  filters: {
    compareDate(val) {
      //将后台发送过来的时间戳转化成日期
      let date = new Date(val)
      let year = date.getFullYear() + ''
      let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()

      // 将系统当天时间戳转化成日期
      let stime = new Date().getTime()
      let sdate = new Date(stime)
      let Y = sdate.getFullYear() + ''
      let D = sdate.getDate() < 10 ? '0' + sdate.getDate() : sdate.getDate()
  
      let times = stime - val
      if (times <= 600000) {
        return '刚刚'
      } else if (times < 3600000) {
        return Math.floor(times / 1000 / 60) + '分钟前'
      } else if (times < 86400000) {
        return Math.floor(times / 1000 / 60 / 60) + '小时前'
      } else if (times < 172800000) {
        let xxx = D - day > 1 ? '前天' : '昨天'
        return xxx
      } else if (times < 2592000000) {
        let yestaday = Math.round(times / 1000 / 60 / 60 / 24)
        let temp = ''
        temp = parseInt(yestaday) === 2 ? '前天' : yestaday + '天前'
        return temp
      } else if (year == Y) {
        return month + '-' + day
      } else {
        return year + '-' + month + '-' + day
      }
    },
    videoDealTime(time) {
      if(time > 3600){
        return Math.floor(time / 3600).toString().padStart(2, '0') + ':' + Math.floor((time-3600) / 60).toString().padStart(2, '0') + ':' + Math.floor(time % 60).toString().padStart(2, '0')
      }else{
        return Math.floor(time / 60).toString().padStart(2, '0') + ':' + Math.floor(time % 60).toString().padStart(2, '0')
      }
    },
  },
  methods: {
    async setWeChatShare({
      url = '',
      title, 
      description = '', 
      friendImg = this.$baseInfo.LOGO_IMG,
      momentsImg = ''
    }) {
      let shareUrl = url || window.location.href
      let { data: res } = await this.$api.queryWxConfig({
          appId: this.$appId,
          url: shareUrl
      })
      if (res.code !== 200) return;

      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: res.data.appId, // 必填， 公众号的唯一标识
        timestamp: res.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
        signature: res.data.signature, // 必填，签名
        jsApiList: ['checkJsApi', 'updateAppMessageShareData', 'updateTimelineShareData'] // 必填，需要使用的JS接口列表
      })
      wx.checkJsApi({
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData'],
        success: function(res) {},
        error: function(res) {}
      })

      wx.ready(function(){
        //分享给朋友 1.4
        wx.updateAppMessageShareData({
          title: title,
          desc: description,
          link: window.location.href,
          imgUrl: this.$Is_IOS && friendImg.includes('.gif') ? friendImg : friendImg + '?x-oss-process=image/format,jpg',
          success: function(){}
        })
        //分享到朋友圈 1.4
        wx.updateTimelineShareData({
          title: title,
          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: this.$Is_IOS && momentsImg.includes('.gif') ? momentsImg : momentsImg + '?x-oss-process=image/format,jpg',
          success: function(){}
        })
      })
      // 错误信息
      wx.error(function(res) {
        console.log('验证失败返回信息', res)
      })
    },
    getUrlParam(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
      var r = window.location.search.substring(1).match(reg);
      if (r != null) return decodeURIComponent(r[2]);
      return null
    },
  }
}