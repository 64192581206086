import {
  HttpGet,
  HttpPost,
  HttpPostConfig,
  HttpGetConfig,
  server,
  yshHttp
} from './config'

import momentModule from './moment';

let Api = {
  //社区优化--评论列表，分页+仅看楼主 POST /contents/comment/list
  queryCommunityCommentList(params) {
    return HttpGet("/kd-content/contents/comment/list/wap", params);
  },
  // 频道轮播图
  queryChannelViewpager(params) {
    return HttpGet("/kd-admin/admin/viewPager/channel/queryForFrontEnd", params);
  },
  //微信二次分享配置
  queryWxConfig(params) {
    return HttpGet('kd-group/wx/mp/jsapiconfig', params)
  },
  //获取sts上传凭证
  stsToken() {
    return HttpGet('kd-group/medio/stsToken')
  },
  getSts() {
    return yshHttp.get('/ecservice/vod/medio/token')
  },
  //获取视频时长
  video_time(id) {
    return HttpGet('kd-group/medio/info/' + id)
  },
  //查询热榜首页数据
  queryHotChartList(id) {
    return HttpGet('kd-content/contents/queryHotChartList' + id)
  },
  //查询热榜更多数据
  queryHotChartListMore(id) {
    return HttpGet('kd-content/contents/queryHotChartListMore' + id)
  },
  queryTopicDetail(id) {
    return HttpGet('kd-content/contents/topic-channel/detail' + id)
  },
  // 获取奥运专题数据
  getOlympicData(id) {
    return HttpGet('kd-content/contents/olympic/topic-channel/detail' + id + '&t=' + Date.now())
  },
  //查看专题频道详情--展开更多
  specialDetailLater(params) {
    return HttpPost('kd-content/contents/topic-channel/detailLater', params)
  },
  //专题分享计数增加
  incrTopicShare(params) {
    return HttpPost('kd-content/contents/olympic/incrTopicShare'+ params)
  },
  // 查询所有列个表页
  app(params) {
    return HttpPost('kd-content/contents/list/app', params)
  },
  // ysh秘锋内容数据分页列表
  queryPageArticle(params) {
    return HttpGet('kd-content/content/ysh/pageArticle', params)
  },
  // ysh秘锋内容数据详情
  queryArticleDetail(params) {
    return HttpGet('/kd-content/content/ysh/articleDetail', params)
  },
  // 查询专题奥运推荐作者
  queryTopicRecommendAuthor() {
    return HttpPost('kd-content/contents/olympic/queryTopicRecommendAuthor')
  },
  // 专题热度加一，返回当前专题总热度
  incrTopicHot(id) {
    return HttpPost('kd-content/contents/olympic/incrTopicHot?'+id)
  },
  // 活动文章列表
  articles(params) {
    return HttpPost('kd-content/contents/topic/articles', params)
  },
  // 查询所有频道及其类目详细信息
  queryRecommendedReading(params) {
    return HttpGet('kd-content/contents/queryRecommendInArticleDetail', params)
  },
  // 作品列表
  topicList(params) {
    return HttpPost('kd-content/contents/topic/list', params)
  },
  // 近期作品列表 (2022/2/21 废弃 产品修改功能)
  queryRecentArticles(params) {
    return HttpGet('kd-content/contents/share/bottom/list', params)
  },
  // 查询所有频道及其类目详细信息
  queryChannel(id) {
    return HttpGet('kd-admin/admin/channel/queryChannelsWithCategoriesByType/' + id + '?tradeType=H5')
  },
  // 下载APP
  getUpdateInfo() {
    return HttpGet('/kd-admin/admin/AppUpdate/getUpdateInfo')
  },
  // 下载APP统计
  countQRCodeDownload() {
    return HttpGet('/kd-ucenter/ucenter/statistic/countQRCodeDownload')
  },
  // 获取wap活动悬浮小弹框
  getActiveBox() {
    return HttpGet('/kd-ucenter/ucenter/commonConfig/getActiveBox')
  },
  // 查询所有频道及其类目详细信息
  queryone(id) {
    return HttpGet('kd-content/contents/queryone/' + id)
  },
  // 查询下载量
  queryUserTotal() {
    return HttpGet('/kd-ucenter/ucenter/statistic/queryUserTotal')
  },
  // 根据频道ID返回栏目信息活动专题使用
  queryActions(id) {
    return HttpGet('/kd-admin/admin/category/queryCategoriesByChannelIdForTopic?channelId=' + id)
  },
  // 查询作品详情
  querywordsD(id) {
    return HttpGet('/kd-content/contents/topic/detail?id=' + id)
  },
  // 查询24h热闻
  queryHotNews(limit) {
    return HttpGet(`/kd-content/contents/hot/3?limit=${limit}&page=1`)
  },
  // 查询24h热贴
  queryHotPosts() {
    return HttpGet('/kd-content/contents/hot/1?limit=50&page=1')
  },
  //广告查询
  queryAdvContent(params) {
    return HttpPost('/kd-ad/ad/query/content', params)
  },
  // 广告曝光量
  queryAdvAddOne(params) {
    return HttpPost(`/kd-ad/ad/exposure/addOne?adId=${params.adId}`)
  },
  queryLoopAdvAddOne(params) {
    return HttpPost(`kd-admin/admin/exposure/viewpagerEntity/addOne?id=${params.adId}`)
  },
  // ------------------------赣菜抽奖--------------------
  //根据ip 检查用户 当天是否抽过奖
  checkIsJoin() {
    return HttpGet('/kd-ucenter/v1/luckDraw/check')
  },
  //抽奖
  turnableSave() {
    return HttpPost('/kd-ucenter/v1/luckDraw/save')
  },
  //用户领取奖品
  getCoupon(params) {
    return HttpPost(`/kd-ucenter/v1/luckDraw/winningRecord/${params.phone}/${params.type}`)
  },
  // ------------------------赣菜抽奖--------------------

  // ------------------------奥运专题------------------------
  // /contents/olympic/incrTopicApplause专题某文章喝彩值增加，返回当前专题某文章喝彩值
  addCheer(params) {
    return HttpPost('/kd-content/contents/olympic/incrTopicApplause', params)
  },
  // /contents/olympic/queryTopicStatic 查询专题热度值，助力值，总金数以及文章对应的喝彩值
  getAllOlympicNum(params) {
    return HttpPost('/kd-content/contents/olympic/queryTopicStatic', params)
  },
  // /contents/olympic/incrTopicAssist 专题助力值增加，返回当前专题总助力
  addHelp(params) {
    return HttpPost('/kd-content/contents/olympic/incrTopicAssist', params)
  },

  // 游戏二次分享信息
  getWxGameInfo(params){
    return HttpGet(`/kd-task/task/getShareConfig/${params}`)
  },

  // 获取财经/健康资质证明
  getHobbyCertificate(params){
    return HttpGet('/kd-admin/admin/text/27')
  },

  // 获取领域认证说明
  getHobbyExplain(params){
    return HttpGet('/kd-admin/admin/text/28')
  },

  // 获取视频详情
  getVideoInfo(vid){
    return HttpPostConfig(`kd-content/video/vid/${vid}`)
  },
  // 查询视频列表页
  videoList(params) {
    return HttpPost('kd-content/contents/list/video', params)
  },

  // 查询全部任务
  allTaskList(params, config){
    return HttpPostConfig('/kd-content/contents/activity/list', params, config)
  },
  // 查询我的任务
  myTaskList(params, config){
    return HttpPostConfig('/kd-content/contents/activity/own/list', params, config)
  },
  // 开始任务
  startMyTask(id, t){
    return HttpPostConfig('/kd-content/contents/activity/receive?activityId=' + id,[], {headers:{ 'Authorization': t}})
  },
  // 查询任务详情
  taskDetail(id,t){
    return HttpGetConfig(`/kd-content/contents/activity?activityId=${id}`, {headers:{ 'Authorization': t}})
  },


  // 查询视频列表页
  getVideoChannel() {
    return HttpGet('/kd-content/v1/video/channelAndCategory')
  },
  // 查询便签聚合页列表页
  labelAggregation(params) {
    return HttpGet(`/kd-content/contents/${params.sortType}/labelAggregation/${params.labelName}/${params.pageNo}/${params.pageSize}`)
  },
  // 上报视频播放记录
  postVideoPlay(params) {
    return HttpPost(`/kd-content/video/history`, params)
  },
  // 获取商品信息
  getGoodsInfo(params) {
    return HttpGet(`/kd-content/contents/activity/goodsInfo`, params)
  },
  // 统计商品卡片点击数据
  postGoodsClick(params) {
    return HttpPost(`/kd-content/contents/activity/goods/click`, params)
  },

  // 老用户已到账未到账总数
  oldUserAccount(params){
    return HttpGetConfig(`kd-task/v1/task/invite/all`,params)
  },
  // 好友进度列表
  friendStepListData(params){
    return HttpGetConfig(`kd-task/v1/task/invite/list`,params)
  },
  // 领取奖励
  receiveTaskReward(params){
    return HttpGetConfig(`kd-task/v1/task/takeReward`,params)
  },
  // 待到账列表
  waitAccount(params){
    return HttpGetConfig(`kd-task/v1/task/invite/wait`,params)
  },
  // 已到账
  alreadyAccount(params){
    return HttpGetConfig(`kd-task/v1/task/invite/already`,params)
  },
  // 查看进度详情
  waitAccountDetail(params){
    return HttpGetConfig(`kd-task/v1/task/invite/detail/${params.uid}`,params)
  },
  // 落地页生成二维码
  invitedPageQRcode(params){
    return HttpPost(`kd-group/oss/createQrCode?content=${params.content}`)
  },
  // 拉新邀请码
  invitedNewUserCode(params){
    return HttpGetConfig(`kd-task/v1/task/getInviteCode`,params)
  },
  // 今日邀请人数
  todayInvitedNumber(params){
    return HttpGetConfig(`kd-task/v1/task/getInviteNumToday`,params)
  },
  // 今日邀请人数
  inviteTime(params){
    return HttpGet(`kd-task/v1/task/getInviteTime`,params)
  },
  getLabelInfo(params) {
    return HttpGet(`kd-content/contents/subject/info`, params)
  },
  // 查询推荐作者
  getRecommendAuthor(params) {
    return HttpGet(`/kd-ucenter/user/recommend/recommendList`, params)
  },
  // 热闻热帖榜
  hotArticleList(params){
    return HttpGet("/kd-content/contents/queryHotChartListWithTag", params);
  },

  // 登录/验证安全指引
  loginGuideText(){
    return HttpGet(`/kd-admin/v1/admin/text/guideText`);
  },

  // 获取类目列表
  getBandIndexCategoryList() {
    return HttpGet(`/kd-group/brand/index/getBandIndexCategoryList`)
  },
  // 获取品牌列表
  getBandIndexListByCategoryId(id) {
    return HttpGet(`/kd-group/brand/index/getBandIndexListByCategoryId/${id}`)
  },

  // 动态详情模块
  ...momentModule
}

export default Api
