import Vue from 'vue'
import VueRouter from 'vue-router'
import { louteToPC } from '@/utils/global-value.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '@/views/home'),
    meta:{
      keepAlive:true
    }
  },
  {
    path: '/topic',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '@/views/home')
  },
  {
    path: '/freeport',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ '@/views/home')
  },
  {
    path: '/note/:id',
    name: 'noteDetail',
    component: () => import(/* webpackChunkName: "note" */ '@/views/home/NewsDetail'),
  },
  {
    path: '/article/:id',
    name: 'articleDetail',
    component: () => import(/* webpackChunkName: "note" */ '@/views/chamber/ArticleDetail'),
  },
  {
    path: '/note/second/:id',
    name: 'noteDetailSecond',
    component: () => import(/* webpackChunkName: "note" */ '@/views/home/NewsDetail'),
  },
  {
    path: '/download',
    name: 'download',
    component: () => import(/* webpackChunkName: "download" */ '@/views/home/Download'),
    meta:{
      showDown: false
    }
  },
  {
    path: '/nav',
    component: () => import(/* webpackChunkName: "nav" */ '@/views/home/NavChannel'),
    meta:{
      showDown: false
    }
  },
  {
    path: '/works/:id',
    component: () => import(/* webpackChunkName: "works" */ '@/views/topic/worksDetail'),
    props: true
  },
  {
    path: '/top/:id/:name',
    component: () => import(/* webpackChunkName: "top" */ '@/views/topic/topicDetail'),
    props: true
  },
  {
    path: '/consult/:pid/:lid',
    component: () => import(/* webpackChunkName: "top" */ '@/views/topic/topicList'),
    props: true
  },
  {
    path: '/special/:id/:channelId',
    name: 'special',
    component: () => import(/* webpackChunkName: "top" */ '@/views/home/SpeicalDetail'),
    props: true
  },
  {
    path: '/olympic/:id/:channelId',
    name: 'olympic',
    component: () => import(/* webpackChunkName: "olympic" */ '@/views/olympic'),
    props: true
  },
  {
    path: '/tab/:id/:channelId',
    name: 'tab',
    component: () => import(/* webpackChunkName: "tab" */ '@/views/olympic/Tab'),
    props: true
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "home" */ '@/views/home')
  },
  {
    path: '/hotlist',
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/HotList')
  },
  {
    path: '/landing/cattle',
    component: () => import(/* webpackChunkName: "landing" */ '@/views/landing/cattle'),
    meta: {
      title:'跟我一起免费领有机鲜牛肉',
      showDown: false
    }

  },
  {
    path: '/turnable',
    component: () => import(/* webpackChunkName: "turnable" */ '@/views/turnable'),
    meta: {
      title:'十大菜系优惠券等你领'
    }
  },
  {
    path: '/received',
    component: () => import(/* webpackChunkName: "received" */ '@/views/turnable/success'),
    meta: {
      title:'领取成功',
      showDown: false
    }
  },
  {
    //养牛攻略页
    path: '/cattlestrategy',
    component: () => import(/* webpackChunkName: "cattlestrategy" */ '@/views/cattle/CattleStrategy'),
    meta: {
      showDown: false
    }
  },
  {
    //养牛攻略规则
    path: '/morestrategy',
    component: () => import(/* webpackChunkName: "cattlestrategy" */ '@/views/cattle/Morestrategy'),
    meta: {
      showDown: false
    }
  },
  {
    //乡村攻略页
    path: '/ruralstrategy',
    component: () => import(/* webpackChunkName: "cattlestrategy" */ '@/views/cattle/RuralStrategy'),
    meta: {
      showDown: false
    }
  },
  {
    //乡村攻略规则
    path: '/ruralrule',
    component: () => import(/* webpackChunkName: "cattlestrategy" */ '@/views/cattle/RuralRule'),
  },
  {
    //about
    path: '/about',
    component: () => import(/* webpackChunkName: "about" */ '@/views/about'),
  },
  {
    //联系我们
    path: '/contact',
    component: () => import(/* webpackChunkName: "contact" */ '@/views/home/contact'),
  },
  {
    // 404页面
    path: '/notfound',
    component: () => import(/* webpackChunkName: "notfound" */ '@/views/home/Notfound'),
  },
  {
    //用戶協議
    path: '/useragreement',
    component: () => import(/* webpackChunkName: "useragreement" */ '@/views/home/useragreement'),
  },
  {
    //小游戏邀请落地页
    path: '/gameInvite',
    component: () => import(/* webpackChunkName: "gameInvite" */ '@/views/home/gameInvite'),
    meta: {
      showDown: false
    }
  },
  {
    //小游戏邀请落地页
    path: '/cdownload',
    component: () => import(/* webpackChunkName: "gameInvite" */ '@/views/home/comDLoad'),
    meta: {
      showDown: false
    }

  },
  {
    // 健康说明
    path: '/hobbyCertificate',
    component: () => import(/* webpackChunkName: "about" */ '@/views/about/hobbyCertificate'),
    meta: {
      showDown: false
    }
  },
  {
    // 领域认证说明
    path: '/hobbyExplain',
    component: () => import(/* webpackChunkName: "about" */ '@/views/about/hobbyExplain'),
    meta: {
      showDown: false
    }
  },
  {
    path: '/landing/userFission',
    component: () => import(/* webpackChunkName: "userFission" */ '@/views/landing/userFission'),
    meta: {
      title:'邀请好友',
      showDown: false
    }
  },
  {
    path: '/landing/account',
    name: 'account',
    component: () => import(/* webpackChunkName: "userFission" */ '@/views/landing/account'),
    meta: {
        title:'待到账',
        showDown: false
    }
  },
  {
    path: '/landing/invited',
    name: 'invited',
    component: () => import(/* webpackChunkName: "userFission" */ '@/views/landing/invited'),
    meta: {
        title:'看文章赚零花',
        showDown: false
    }
  },
  {
    path: '/moment/:id',
    name: 'momentDetail',
    component: () => import(/* webpackChunkName: "momentDetail" */ '@/views/home/momentDetail'),
    props: true
  },
  {
    path: '/lableaggregatepage/:id',
    name: 'LabelAggregatePage',
    component: () => import(/* webpackChunkName: "LabelAggregatePage" */ '@/views/home/LabelAggregatePage'),
    meta: {
      keepAlive: true,
      showDown: false
    }
  },
  {
    path: '/framertoapp',
    name: 'framer',
    component: () => import(/* webpackChunkName: "Framer" */ '@/views/home/Framer'),
    meta: {
      title:'有奖任务',
      showDown: false
    }
  },
  {
    path: '/framerdetailtoapp/:id',
    name: 'framerdetailtoapp',
    component: () => import(/* webpackChunkName: "Framer" */ '@/views/home/FramerDetail'),
    props: true,
    meta: {
      title:'任务详情',
      showDown: false
    }
  },
  {
    path: '/creatorInvite',
    name: 'creatorInvite',
    component: () => import(/* webpackChunkName: "Framer" */ '@/views/share/creatorInvite'),
    props: true,
    meta: {
      title:'分享价值内容，凯迪助你获得更多收益！',
      showDown: false
    }
  },
  {
    path: '/loginGuide',
    name: 'loginGuide',
    component: () => import(/* webpackChunkName: "about" */ '@/views/about/loginGuide'),
    meta: {
      title:'没绑定手机号/邮箱要重置密码？',
      showDown: false
    }
  },
  {
    path: '/yyds',
    name: 'yyds',
    component: () => import(/* webpackChunkName: "about" */ '@/views/landing/yyds'),
    meta: {
      title:'优云电商',
      showDown: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if(savedPosition) {
      return savedPosition
    }else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

// 进入页面做一些处理
router.beforeEach((to, from, next) => {
  var result = louteToPC();
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (result) {
    if(to.query.openIosApp){
      window.location = "https://apps.apple.com/cn/app/%E5%87%AF%E8%BF%AA%E6%96%B0%E9%97%BB-%E6%9C%89%E4%BD%A0-%E6%9B%B4%E6%9C%89%E5%BD%B1%E5%93%8D%E5%8A%9B/id1533642383";
    }
  }
  next();
  try {
    window._hmt = window._hmt || []
    window._hmt.push(['_trackPageview', to.fullPath])
  } catch (e) {
    console.log('tongError', e)
  }
})

export default router
