import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import constant from './constant'
// vant组件
import Vant from 'vant'
import 'vant/lib/index.css'
Vue.use(Vant)

// 引入全局样式
import '@/assets/css/index.less'

//  自动设置 REM 基准值（html标签字体大小）
import 'amfe-flexible'

// 全局接口
import Api from '@/api/data'
Vue.prototype.$api = Api

Vue.config.productionTip = false

// 全局函数
import globalValue from './utils/global-value.js'
for (const key in globalValue) {
  let name = '$' + key
  Vue.prototype[name] = globalValue[key]
}

// 全局常量
Vue.prototype.$constant = constant

import 'babel-polyfill'
import Es6Promise from 'es6-promise'

require('es6-promise').polyfill()
Es6Promise.polyfill()

import { menuMix } from '@/utils/mixins.js'
Vue.mixin(menuMix)

// 创作者任务轮播图
import 'swiper/dist/css/swiper.min.css'

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
