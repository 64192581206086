<template>
  <div id="app" :class="{'pc-app' : $Is_PC }">
    <!--  :key="this.$route.path" 解决this.$router.push跳转到当前页，页面没有刷新问题   -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" :key="this.$route.path"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :key="this.$route.path"></router-view>

    <div class="suspended-adv" v-if="ad.show === 1" v-show="$store.state.status">
      <span @click="close" class="iconfont close">&#xe687;</span>
      <router-link :src="ad.picUrl" tag="img" to="/download"></router-link>
    </div>
    <div class="foot-wrap" v-if="showDown !== null && showDown" id="foot-open-box" :style="{'width': $Is_PC ? '500px' : ''}">
      <div class="footer" >
        <div class="f-left">
          <img :src="$store.state.downloadcss ?  $baseInfo.LOGO_IMG : 'https://cdn.9kd.com/kdnet/oly_logo.png'" alt="" />
          <p>
            <span>{{$store.state.downloadcss ? $baseInfo.SLOGAN: '凯迪陪你看奥运！'}}</span>
            <span class="app">凯迪网APP</span>
          </p>
        </div>
        <div class="f-right">
          <div class="open-btn" @click="showOpenAppDialog(null, undo)" id="openBtn">打开</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from './utils/bus';
export default {
  data() {
    return {
      ad: '',
      showDown: null,
      timer: null,
      downTimer: null,
      openTimer: null,
      noteInfo: {},
      continuteType: null,
      isHideBrowser: false,
      isShowBrowser: false,
      lastIosTime: 0,
      undo: Bus.CONTINUTETYPE_UNDO
    }
  },
  created() {
    if (!this.$route.path.includes('framerdetailtoapp') || !['/framertoapp'].includes(this.$route.path)) {
      this.getActiveBox();
    }
  },
  async mounted() {
    this.listenerBrowser();
    Bus.$on(Bus.SHOW_DOWN_FLOATING, show => {
      this.showDown = show;
    });
    Bus.$on(Bus.SHOW_OPEN_DIALOG, data => {
      this.noteInfo = data.noteInfo;
      this.continuteType = data.continuteType;
      // 不提示，直接进入的类型
      if (this.directOpenAppType(this.continuteType)) {
        return;
      }
      this.showOpenAppDialog(this.noteInfo, this.continuteType);
    });
    this.timer = setTimeout(() => {
      if (this.showDown === null) {
        this.showDown = true;
      }
    }, 2000);
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  methods: {
    close() {
      this.$store.commit('close')
    },
    // 获取wap活动悬浮小弹框
    getActiveBox() {
      this.$api.getActiveBox().then(res => {
        let data = res.data;
        if (data.code !== 200) return
        this.ad = data.data
      })
    },
    // 直接进入APP的类型
    directOpenAppType(continuteType) {
      if (continuteType === Bus.CONTINUTETYPE_REPLY ||
        continuteType === Bus.CONTINUTETYPE_COMMENT ||
        continuteType === Bus.CONTINUTETYPE_PRAISE ||
        continuteType === Bus.CONTINUTETYPE_AUTHOR ||
        continuteType === Bus.CONTINUTETYPE_ARTICLE) {
        this.openKdApp();
        return true;
      }
      return false;
    },
    // 弹窗方法
    showOpenAppDialog(noteInfo, continuteType) {
      this.noteInfo = noteInfo;
      this.continuteType = continuteType;
      if(window.location.pathname === '/turnable'){
        window._hmt = window._hmt || []
        window._hmt.push(['_trackEvent', '转盘页下载', '点击', '点击底部']) //赣菜下载事件跟踪
      }
      this.openKdApp();
    },
    /**
     * 检查凯迪App是否安装
     */
    openKdApp() {
      this.clearTimer();
      if ((this.$Is_WeChat || this.$Is_QQ) && this.$Is_Android) {
        // 创作者邀请下载固定链接
        if (this.$store.state.dlChannel) {
          let path = `/download#${this.$store.state.dlChannel}`
          this.$router.push(path);
          return
        }
        let schema = this.$getAppUrl(this.continuteType, this.noteInfo);
        let url = 'https://a.app.qq.com/o/simple.jsp?pkgname=net.kdnet.club&android_schema=';
        url += encodeURIComponent(schema)
        window.location.href = url;
        return
      }
      if (this.$Is_IOS) {
        this.isHideBrowser = false;
        let nowTime = new Date().getTime();
        if (nowTime - this.lastIosTime < 5000) {
          return;
        }
        this.lastIosTime = nowTime;
        let url = this.$getAppUrl(this.continuteType, this.noteInfo);
        window.location = url;
      } else {
        this.openAndroid();
      }

    },
    /**
     * 打开安卓应用
     */
    openAndroid() {
      let url = this.$getAppUrl(this.continuteType, this.noteInfo);
      this.isHideBrowser = false;
      window.location.href = url;
      this.checkAppDialogOpen();
      let self = this;
      this.downTimer = setTimeout(function () {
        if (self.isHideBrowser === false && self.isShowBrowser === false) {
          //未安装app
          let path = self.$store.state.dlChannel ? `/download#${self.$store.state.dlChannel}` : self.$store.state.downloadcss ? '/download': '/download#vx_grounds'
          self.$router.push(path)

        }
      }, 2000);
    },
    /**
     * 检查 app 是否打开
     */
    checkAppDialogOpen() {
      let self = this;
      function checkOpen(){
        var _clickTime = +(new Date());
        function check(elsTime) {
          if ( elsTime > 3000) {
            self.isShowBrowser = true;
          } else {
            self.isShowBrowser = false;
          }
        }
        // 启动间隔 20ms 运行的定时器，并检测累计消耗时间是否超过 3000ms，超过则结束
        var _count = 0, intHandle;
        intHandle = setInterval(function(){
          _count++;
          var elsTime = +(new Date()) - _clickTime;
          if (_count >= 100 || elsTime > 3000 ) {
            clearInterval(intHandle);
            check(elsTime);
          }
        }, 25);
      }
      checkOpen();
    },
    /**
     * 清除定时器
     */
    clearTimer() {
      if (this.downTimer) {
        clearTimeout(this.downTimer)
      }
      if (this.openTimer) {
        clearTimeout(this.openTimer)
      }
    },
    /**
     * 监听浏览器的打开和隐藏
     */
    listenerBrowser() {
      document.addEventListener("visibilitychange", () => {
        if (document.hidden) {
          this.isShowBrowser = false;
          this.isHideBrowser = true;
        } else {
          this.isShowBrowser = true;
        }
      });
    },
  },
  watch: {
    $route(to) {
      if(to.meta && to.meta.showDown != undefined) {
        this.showDown = false;
      }else {
        this.showDown = true;
      }
    }
  }
}
</script>