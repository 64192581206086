/****
 * 空的vue实例用于兄弟组件之间通信
 */
import Vue from 'vue';

let Bus = new Vue;
Bus.SHOW_DOWN_FLOATING = 'show_down_floating';
Bus.SHOW_LOOP_FLOATING = 'show_loop_floating';
Bus.SHOW_OPEN_DIALOG = 'show_open_dialog';
Bus.SHOW_KD_APP_DIALOG = "show_kd_app_dialog";
Bus.ENTER_ARTICEL_DETAIL = 'enter_articel_detail';
Bus.ENTER_AUTHOR_PAGE = 'enter_author_page';
Bus.ENTER_HOT_NEW = 'enter_hot_new';
Bus.ENTER_HOT_INVITATION = 'enter_hot_invitation';
Bus.UPDATE_NEW_INFO = 'update_new_info';


Bus.CONTINUTETYPE_UNDO = "undo";
Bus.CONTINUTETYPE_REPLY = "reply";
Bus.CONTINUTETYPE_COMMENT = "comment";
Bus.CONTINUTETYPE_PRAISE = "praise";
Bus.CONTINUTETYPE_AUTHOR = "author";
Bus.CONTINUTETYPE_ARTICLE = "article";

Bus.CONTINUTETYPE_ARTICLE_DETAIL = "article-detail";
Bus.CONTINUTETYPE_LOOK_COMMENT = "look-comment";
Bus.CONTINUTETYPE_LOOK_ARTICLE = "look-article";
Bus.CONTINUTETYPE_ARTICLE_DETAIL_RECOMMEND = "article-detail-recommend";

Bus.CONTINUTETYPE_HOT_NEW = "hot-new";
Bus.CONTINUTETYPE_HOT_POST = "hot-post";
Bus.MOMENT_DETAIL = 'moment_detail';
Bus.LABEL_DETAIL = 'label_list';

export default Bus;

