import {
  HttpGet
} from './config'

let getDynamicDetail = (params) => {
  return HttpGet(`/kd-content/v1/cont/${params.code}/momentDetail`);
}

export default {
  getDynamicDetail
}