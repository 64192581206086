// 创建时间转换
var timeToString = (val) =>{
    //将后台发送过来的时间戳转化成日期
    let date = new Date(val)
    let year = date.getFullYear() + ''
    let month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    // let timer = year + month + day
    // 将系统当天时间戳转化成日期
    let stime = new Date().getTime()
    let sdate = new Date(stime)
    let Y = sdate.getFullYear() + ''
    let D = sdate.getDate() < 10 ? '0' + sdate.getDate() : sdate.getDate()

    let times = stime - val
    if (times <= 600000) {
      return '刚刚'
    } else if (times < 3600000) {
      return Math.floor(times / 1000 / 60) + '分钟前'
    } else if (times < 86400000) {
      return Math.floor(times / 1000 / 60 / 60) + '小时前'
    } else if (times < 172800000) {
      let xxx = D - day > 1 ? '前天' : '昨天'
      return xxx
    } else if (times < 2592000000) {
      let yestaday = Math.round(times / 1000 / 60 / 60 / 24)
      let temp = ''
      temp = parseInt(yestaday) === 2 ? '前天' : yestaday + '天前'
      return temp
    } else if (year == Y) {
      return month + '-' + day
    } else {
      return year + '-' + month + '-' + day
    }
}

// 视频时间转换
let stringVideoTime = (time,isString) =>{
    // 小于60秒
    if (time < 60) {
        if(isString){
            time = '00:' + parseInt(time).toString().padStart(2,'0');
        }else{
            time = parseInt(time) + '秒';
        }
    }
    // 大于60秒小于一个小时
    else if (time > 60 && time < 3600) {
        if(isString){
            time = Math.floor(time / 60).toString().padStart(2,'0') + ':' + Math.floor(time % 60).toString().padStart(2,'0');
        }else {
            time = Math.floor(time / 60) + '分' + Math.floor(time % 60) + '秒';
        }
    }
    // 大于1小时
    else if (time >= 3600) {
        if(isString){
            time = Math.floor(time / 60 / 60).toString().padStart(2,'0') + ':' + Math.floor(time % 60).toString().padStart(2,'0') + ':' + Math.floor((time - 3600) % 60).toString().padStart(2,'0');
        }else {
            time = Math.floor(time / 60 / 60) + '时' + Math.floor(time % 60) + '分' + Math.floor((time - 3600) % 60) + '秒';
        }
    }
    return time
}

export {
    timeToString,
    stringVideoTime
}