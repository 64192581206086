import axios from 'axios'
import { Sentry } from '@/utils/sentry'

const server = process.env.VUE_APP_BASE_URL + '/api'

const defaultHttp = axios.create({
  baseURL: server,
  timeout: 10000
})

function handleHttpInterceptors(http) {
  http.interceptors.request.use(
    config => {
      config.headers.platform = 'wap'
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )
  http.interceptors.response.use(
    config => {
      return config
    },
    error => {
      let env = process.env.NODE_ENV
      // 处理
      Sentry.setTags({
        'env': env
      })
      Sentry.captureException(error);
      return Promise.reject(error)
    }
  )
  return http
}


const setHttp = function(settings) {
  let http = axios.create({
    baseURL: `${process.env.VUE_APP_YSH_BASE_URL}/`,
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    ...settings,
  })

  handleHttpInterceptors(http)

  return http
}

const http = handleHttpInterceptors(defaultHttp)
const yshHttp = setHttp()

const HttpGet = function(url, param) {
  if (param) return http.get(url, { params: param })
  return http.get(url)
}
const HttpGetN = function(url) {
  return http.get(url)
}

const HttpPost = function(url, params) {
  return http.post(url, params)
}

const HttpPostConfig = function(url, params, config) {
  return http.post(url, params, config);
};

const HttpGetConfig = function(url, params) {
  return http.get(url, params);
};

export { HttpGet, HttpPost, HttpPostConfig, HttpGetN, server, HttpGetConfig, setHttp, yshHttp }
