import { timeToString, stringVideoTime } from './videoTime';
import { ttsRecorder } from '@/assets/js/ttsRecords'
import Bus from './bus';

/**
 * 判断设备是否为手机
 * @returns 
 */
let isMobile = () => {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
  return flag
}

/**
 * 路由跳转PC
 * @returns 
 */
export let louteToPC = () => {
  var flag = false;
  if (isMobile()) {
    flag = true
  }
  return flag
}

// 基本信息
let baseInfo = {
  LOGO_IMG: 'https://cdn.9kd.com/kdnet/lALPBG1Q5p3ew2PMwMzA_192_192.png', // logo
  MOMENTS_LOGO_IMG: 'https://cdn.9kd.com/kdnet/lALPBG1Q5p3ew2PMwMzA_192_192_wechat_bg.png',
  SLOGAN: '千万老板都在看', // slogan
  DEFAULT_AVATAR: 'https://cdn.9kd.com/kdnet/a6593d8447984c8a9fc798ad5c327a1d.png', // 默认头像
  AUTHER_IMAGE: 'https://cdn.9kd.com/kdnet/%E8%AE%A4%E8%AF%81%403x.png', // 认证大V图片
  AUTHER_SMALL_IMAGE: 'https://cdn.9kd.com/kdnet/kd-net-VIP.png', // 认证小vip图片
}

// 设备判断
let Navigator = navigator.userAgent;
let Is_WeChat =  Navigator.indexOf("MicroMessenger") >= 0;
let Is_IOS = !!Navigator.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
let Is_Android = Navigator.match(/(Android);?[\s\/]+([\d.]+)?/);
let Is_QQ = Navigator.match(/\sQQ/i) == " qq";
let Is_PC = !isMobile();

// 微信appid
let appId = 'wxc69590fb1be68d95';

/**
 * wap打开唤醒APP
 * @param {*} continuteType 
 * @param {*} noteInfo 
 * @returns 
 */
let getAppUrl = (continuteType, noteInfo) =>{
  let origin = location.origin.indexOf("m.9kd.com") < 0  ? 'https://kdnet-test1-www-inc.9kd.com' : 'https://www.9kd.com'
  var urlParams = {}
  if(location.search != ''){
    var ls = location.search.substring(1);
    var params = ls.split('&');
    for (let key of params) {
      let data = key.split('=');
      if(data[0] != 'openIosApp' && data[0] != 'type' && data[0] != 'id'){
        urlParams[data[0]] = data[1];
      }
    }
  }
  var query = '';
  for (let key in urlParams) {
    query = '&' + key + '=' + urlParams[key]
  }
  let url = '';
  let iosHome = location.pathname + '?openIosApp=true'
  if (continuteType === Bus.CONTINUTETYPE_UNDO || !continuteType) {
    url = Is_IOS ? iosHome : `kdapp://main:7700`;
  } else if (continuteType === Bus.CONTINUTETYPE_HOT_NEW) {
    url = Is_IOS ? iosHome : `kdapp://main:7700/hotnew`;
  } else if (continuteType === Bus.CONTINUTETYPE_HOT_POST) {
    url = Is_IOS ? iosHome : `kdapp://main:7700/hotpost`;
  } else if (continuteType === Bus.MOMENT_DETAIL) {
    url = Is_IOS 
    ? location.pathname + `?momentCode=${noteInfo.code}&openIosApp=true${query}` 
    : `kdapp://article:8000/moment?code=${noteInfo.code}`;
  } else if (continuteType === Bus.LABEL_DETAIL) {
    url = Is_IOS
    ? location.pathname  + `/lableaggregatepage?labelName=${encodeURIComponent(noteInfo.labelName)}&sortType=${noteInfo.sortType}&openIosApp=true${query}`
    : `kdapp://article:8000/lableaggregatepage?labelName=${noteInfo.labelName}&sortType=${noteInfo.sortType}`;
  } else if (continuteType === Bus.CONTINUTETYPE_ARTICLE_DETAIL ||
    continuteType === Bus.CONTINUTETYPE_LOOK_COMMENT ||
    continuteType === Bus.CONTINUTETYPE_LOOK_ARTICLE ||
    continuteType === Bus.CONTINUTETYPE_ARTICLE ||
    continuteType === Bus.CONTINUTETYPE_ARTICLE_DETAIL_RECOMMEND) {
    if (noteInfo) {
      url = Is_IOS
      ? location.pathname + `?id=${noteInfo.id}&type=${noteInfo.type}&openIosApp=true${query}`
      : `kdapp://article:8000/detail?id=${noteInfo.id}&type=${noteInfo.type}`;
    } else {
      url = Is_IOS ? iosHome : `kdapp://main:7700`;
    }
  } else if (continuteType === Bus.CONTINUTETYPE_AUTHOR) {
    if (noteInfo && noteInfo.author) {
      url = Is_IOS ? iosHome : `kdapp://author:8000/center?id=${noteInfo.author.id}`;
    } else if (noteInfo && noteInfo.userInfo) {
      url = Is_IOS ? iosHome : `kdapp://author:8000/center?id=${noteInfo.userInfo.id}`;
    } else {
      url = Is_IOS ? iosHome : `kdapp://main:7700`;
    }
  } else if (continuteType === Bus.CONTINUTETYPE_REPLY ||
    continuteType === Bus.CONTINUTETYPE_COMMENT ||
    continuteType === Bus.CONTINUTETYPE_PRAISE) {
    if (noteInfo) {
      url = Is_IOS 
      ? location.pathname + `?id=${noteInfo.id}&type=${noteInfo.type}&openIosApp=true${query}` 
      : `kdapp://article:8000/detail?id=${noteInfo.id}&type=${noteInfo.type}`;
    } else {
      url = Is_IOS ? iosHome : `kdapp://main:7700`;
    }
  }
  return Is_IOS ? (origin + url) : url;
}

/**
 * 替换换行和空格
 * @param {*} str 
 * @returns 
 */
let replaceRegular = (str) => {
  return str.replace(/\r\n/g, '<br/>').replace(/\n/g, '<br/>').replace(/\s/g, '&nbsp;')
}

var documentHeight = document.documentElement.clientHeight;

var globalVlaue = {
  isMobile,
  louteToPC,
  timeToString, 
  stringVideoTime,
  ttsRecorder,
  baseInfo,
  Is_WeChat,
  Is_IOS,
  Is_Android,
  Is_QQ,
  Is_PC,
  appId,
  getAppUrl,
  replaceRegular,
  documentHeight
}

export default globalVlaue