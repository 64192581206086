import Vue from 'vue'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import router from '@/router'

// 监控
let proSentryUrl = 'https://3b39efd6d6af45b496a42f36f655e977@sentry.9kd.com/4';
let devSentryUrl = 'https://da3367285a1941b9829c82a7d6dd2fe1@public-test-sentry.9kd.com/12';
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: window.location.host === 'm.9kd.com' ? proSentryUrl : devSentryUrl,
    initialScope: {
      tags: {"projectName": "WAP"}
    },
    environment: process.env.NODE_ENV,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      }),
    ],
    tracesSampleRate: 1.0,
    // 错误打印
    logErrors: true
  });
}
export {
  Sentry
}