import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    channelID: 0,
    status: true,
    tabIndex: 0, // 默认专题模板2选中项
    downloadcss: true, // 默认底部的下载是有你，更有影响力的样式
    dlChannel: ''
  },
  mutations: {
    increment(state, num) {
      state.channelID = num
    },
    close(state) {
      state.status = false
    },
    changeTabIndexValue(state, num) { // 修改专题模板2选中项
      state.tabIndex = num
    },
    changeDownloadCss(state,bol) {
      state.downloadcss = bol
    },
    downloadChannel(state, value) {
      state.dlChannel = value
    }
  },
  actions: {},
  modules: {}
})
